<template>
  <div class="carrer">
    <neko-separator :separator_text="$t('CAREER')" />
    <b-container>
      <p>
        <strong>
          {{ $t("CAREER_P1") }}
        </strong>
      </p>
      <p>
        <strong>
          {{ $t("CAREER_P2") }}
        </strong>
      </p>
      <p>
        <strong>
          {{ $t("CAREER_P3") }}
        </strong>
      </p>
      <br />
    </b-container>
    <neko-separator :separator_text="$t('POSITION_OPEN')" />
    <b-container>
      <p>
        {{ $t("CAREER_P37") }}
        <a href="mailto:liam@neko-logic.com">{{ $t("CAREER_P38") }}</a>
      </p>
      <p>
        <strong>{{ $t("CAREER_P39_HEADER") }}</strong>
      </p>
      <ul style="margin-left: 25px">
        <li>{{ $t("CAREER_P39_LIST_1") }}</li>
        <li>{{ $t("CAREER_P39_LIST_2") }}</li>
        <li>{{ $t("CAREER_P39_LIST_3") }}</li>
        <li>{{ $t("CAREER_P39_LIST_4") }}</li>
        <li>{{ $t("CAREER_P39_LIST_5") }}</li>
      </ul>

      <p>
        <strong>{{ $t("CAREER_P40_HEADER") }}</strong>
      </p>
      <ul style="margin-left: 25px">
        <li>{{ $t("CAREER_P40_LIST_1") }}</li>
        <li>{{ $t("CAREER_P40_LIST_2") }}</li>
        <li>{{ $t("CAREER_P40_LIST_3") }}</li>
        <li>{{ $t("CAREER_P40_LIST_4") }}</li>
        <li>{{ $t("CAREER_P40_LIST_5") }}</li>
      </ul>
      <p>
        <strong>{{ $t("CAREER_P41_HEADER") }}</strong>
      </p>
      <ul style="margin-left: 25px">
        <li>{{ $t("CAREER_P41_LIST_1") }}</li>
        <li>{{ $t("CAREER_P41_LIST_2") }}</li>
        <li>{{ $t("CAREER_P41_LIST_3") }}</li>
      </ul>
      <br />
    </b-container>
    <neko-separator :separator_text="$t('POSITION_GENERAL')" />
    <b-container>
      <p>
        {{ $t("CAREER_P43") }}
      </p>
      <p>
        <strong> {{ $t("CAREER_P4") }}</strong>
      </p>
      <p>
        {{ $t("CAREER_P5") }}
      </p>
      <p>{{ $t("CAREER_P6") }}</p>
      <ul style="margin-left: 25px">
        <li>{{ $t("CAREER_P8") }}</li>
        <li>{{ $t("CAREER_P9") }}</li>
        <li>{{ $t("CAREER_P10") }}</li>
        <li>Kotlin/Swift</li>
        <li>{{ $t("CAREER_P12") }}</li>
        <li>{{ $t("CAREER_P13") }}</li>
        <li>{{ $t("CAREER_P14") }}</li>
      </ul>
      <p>
        {{ $t("CAREER_P15") }}
      </p>
      <p>
        {{ $t("CAREER_P16") }}
      </p>
      <br />
      <a
        href="mailto:liam@neko-logic.com?subject=I wanna work as an engineer for neko!"
      >
        {{ $t("CAREER_P17") }}
      </a>
      <br />
      <br />
      <br />
      <p>
        <strong> {{ $t("CAREER_P18") }}</strong>
      </p>
      <p>
        {{ $t("CAREER_P19") }}
      </p>
      <p>{{ $t("CAREER_P20") }}</p>
      <ul style="margin-left: 25px">
        <li>
          {{ $t("CAREER_P21") }}
        </li>
        <li>{{ $t("CAREER_P22") }}</li>
        <li>
          {{ $t("CAREER_P23") }}
        </li>
        <li>
          {{ $t("CAREER_P24") }}
        </li>
      </ul>
      <br />
      <a
        href="mailto:liam@neko-logic.com?subject=I wanna work as an producer/planner for neko!"
      >
        {{ $t("CAREER_P17") }}
      </a>
      <br />
      <br />
      <br />
      <p>
        <strong>{{ $t("CAREER_P25") }}</strong>
      </p>
      <p>
        {{ $t("CAREER_P26") }}
      </p>
      <ul style="margin-left: 25px">
        <li>
          {{ $t("CAREER_P27") }}
        </li>
        <li>
          {{ $t("CAREER_P28") }}
        </li>
        <li>
          {{ $t("CAREER_P29") }}
        </li>
        <li>
          {{ $t("CAREER_P30") }}
        </li>
      </ul>
      <br />
      <a
        href="mailto:liam@neko-logic.com?subject=I wanna work as an artist/designer for neko!"
      >
        {{ $t("CAREER_P17") }}
      </a>
      <br />
      <br />
      <br />
      <strong>
        <p>
          {{ $t("CAREER_P31") }}
        </p>
        <p>
          {{ $t("CAREER_P32") }}
        </p>
        <p>
          {{ $t("CAREER_P33") }}
          <a href="https://neko-logic.com/">neko-logic.com</a>
          {{ $t("CAREER_P33_2") }}
        </p>
        <p>
          {{ $t("CAREER_P34") }}
        </p>
        <p>
          {{ $t("CAREER_P35") }}
        </p>
      </strong>
      <br />
    </b-container>
    <neko-separator :separator_text="$t('CAREER_DIVERSITY')" />
    <b-container>
      <p>
        <strong>
          {{ $t("CAREER_P36") }}
        </strong>
      </p>
      <br />
      <br />
      <br />
    </b-container>
    <br />
  </div>
</template>

<script>
import NekoSeparator from "@/components/NekoSeparator.vue";

export default {
  name: "Career",
  components: {
    "neko-separator": NekoSeparator,
  },
};
</script>

<style scoped></style>
